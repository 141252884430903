<template>
  <div class="row py-2 px-3 border-top align-items-md-center">
    <div class="col-md-2 col-12">
      <b-form-select v-model="roomOccupation" :options="roomOccupationOptions"></b-form-select>
    </div>
    <div class="col-md-2 col-12">
      <b-form-select v-model="roomKind">
        <b-form-select-option :value="cls.value" v-for="(cls, inx) in optionRoomClasses" :key="inx" v-b-tooltip.hover :title="cls.desc" >
          {{ cls.text }}
        </b-form-select-option>
      </b-form-select>
    </div>
    <div class="col-md-2 col-12">
      <b-form-select v-model="basis" :options="optionBasis"></b-form-select>
    </div>
    <div class="col-md-2 col-12 text-center mt-1 px-0" style="white-space: nowrap;">
      <h6 v-if="noHotels"></h6>
      <b-link v-else-if="remarks !== ''" href="#" v-b-tooltip.html :title="remarks">
        Terms & Conditions
        <i class="fa fa-exclamation-circle ml-2" v-if="device === 'mobile' && remarks !== ''"></i>
      </b-link>
      <!-- <b-link v-else href="#" v-b-tooltip.hover :title="currentHotel.remark">
        {{ currentHotel.priceList }}
        <i class="fa fa-exclamation-circle ml-2" v-if="device==='mobile' && currentHotel.priceList"/>
      </b-link> -->
    </div>
    <div class="col-md-2 col-10 d-flex text-center m-auto" style="white-space: nowrap;" v-if="!isLoading">
      <h6 v-if="noHotels">{{ $t('search-result.no-hotels') }}</h6>

      <div class="price-area m-auto w-100" v-else-if="isMEMSHAKAgent"><h3><s v-if="showStrikethroughPrice">${{originalTotalPrice}}</s>{{`$${discountedPrice}` }}</h3></div>

      <!-- <div v-else-if="currentHotel.supplierCode === 'ATLANTIS'">
        <h5 class="m-0">{{ $t('search-result.full-price') }}: <span>${{ discountedPrice }}</span></h5>
        <h5 class="m-0">{{ $t('search-result.net-price') }}: <span>${{ Math.round(currentHotel.total * (1 - 3 / 100)) }}</span></h5>
      </div> -->
      <div class="price-area m-auto w-100" v-else>
        <h5 class="m-0">
          <span class="col-6 p-0 text-left">{{ $t('search-result.full-price') }}: </span>
          <span class="col-6 ml-1 p-0 text-right"><s v-if="showStrikethroughPrice">${{originalTotalPrice}}</s>${{ discountedPrice }}</span>
        </h5>
        <h5 class="m-0">
          <span class="col-6 p-0 text-left">{{ $t('search-result.net-price') }}:</span>
          <span class="col-6 ml-1 p-0 text-right"> ${{ Math.round(discountedPrice * (1 - commission / 100)) }}</span>
        </h5>
      </div>
    </div>
    <div v-if="isLoading" class="col-md-2 col-12 text-center"><b-spinner variant="primary"></b-spinner></div>
    <div v-else class="col-md-2 col-12 text-center">
      <!-- <h3 v-if="noHotels">{{ $t('search-result.no-hotels') }}</h3> -->
      <b-button v-if="!noHotels" variant="info" size="sm" @click="orderNow">{{ $t("search-result.order-now") }}</b-button>
    </div>
  </div>
</template>

<script>
import { BFormSelect, BLink, BButton,
  BFormSelectOption,
  BSpinner,
} from 'bootstrap-vue';
import { mapGetters } from 'vuex';

export default {
  name: 'moreHotelPrice',
  components: {
    BFormSelect,
    BLink,
    BButton,
    BFormSelectOption,
    BSpinner,
  },
  props: {
    hotel: {
      type: Object,
      default: null,
    },
    manualDiscount: {
      type: Number,
      default: 0,
    },
    itemIndex: {
      type: Number,
      default: 0,
    },
    commission: {
      type: Number,
      default: 0,
    },
    supplierCode: {
      type: String,
      default: '',
    },
    priceList: {
      type: String,
      default: null,
    },
    hotelCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      roomOccupation: `${this.$route.query.adult},${this.$route.query.child}`,
      roomKind: this.hotel.roomCode,
      basis: this.hotel.basis,
      query: this.$route.query,
      currentHotel: {},
      submitHotels: null,
      isLoading: false,
      suppliers: this.hotel.supplierCode || this.hotel.suppliers || this.hotel.supplier,
      noHotels: false,
      oldKind: null,
      oldBasis: null,
    };
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      marketerId: 'GET_MARKETER_ID',
      subMarketerId: 'GET_SUB_AGENCY',
      isMEMSHAKAgent: 'IS_MEMSHAK_ODYSSEY_AGENT_CODE',
    }),
    roomTypeArray() {
      return [
        ['2,0', 'couple'],
        ['2,1', 'couple-child'],
        ['2,2', 'couple-2-child'],
        ['2,3', 'couple-3-child'],
        ['2,4', 'couple-4-child'],
        ['1,0', 'single'],
        ['1,1', 'single-child'],
        ['1,2', 'single-2-child'],
        ['3,0', 'three-adult'],
        ['3,1', 'three-adult-child'],
        ['3,2', 'three-adult-2-child'],
        ['4,0', 'four-adult'],
        ['4,1', 'four-adult-child'],
        ['4,2', 'four-adult-2-child'],
        ['5,0', 'five-adult'],
        ['6,0', 'six-adult'],
        ['7,0', 'seven-adult'],
        ['8,0', 'eight-adult'],
      ];
    },
    roomOccupationOptions() {
      return this.roomTypeArray.map((item) => ({ value: item[0], text: this.$t(`room-type-list.${item[1]}`) }));
    },
    optionRoomClasses() {
      const { submitHotels } = this;
      const hotels = submitHotels || this.hotel.restHotels;
      if (hotels.length === 0) {
        return [];
      }
      const roomCodes = [
        ...new Set(hotels.map((item) => item.roomCode)),
      ];
      const options = roomCodes.map((elm) => ({
        value: elm,
        text: hotels.find((elem) => elem.roomCode === elm).roomName,
        desc: hotels.find((elem) => elem.roomCode === elm).roomDesc,
      }));
      return options;
    },
    optionBasis() {
      const { submitHotels } = this;
      const hotels = submitHotels || this.hotel.restHotels;
      const { roomKind } = this;
      if (hotels.length === 0) {
        return [];
      }
      const basis = [
        ...new Set(hotels.filter((elm) => (elm.roomCode === roomKind)).map((elm) => elm.basis)),
      ];
      const options = basis.map((elm) => ({ value: elm, text: this.$t(`basis-name.${elm.toLowerCase()}`) }));
      return options;
    },
    discountedPrice() {
      const { manualDiscount, currentHotel } = this;
      const { total, totalAfterDiscounted } = currentHotel;
      if (this.isMEMSHAKAgent) {
        return Math.round(total * (1 - manualDiscount / 100));
      } else {
        return Math.round(totalAfterDiscounted);
      }
    },
    showStrikethroughPrice() {
      const { manualDiscount, currentHotel } = this;
      if (manualDiscount > 0 || currentHotel.discountPercent > 0) {
        return true;
      } else {
        return false;
      }
    },
    originalTotalPrice() {
      const { total } = this.currentHotel;
      return Math.round(total);
    },
    queryBody() {
      const adult = this.roomOccupation.split(',')[0];
      const child = this.roomOccupation.split(',')[1];
      const { infant } = this.query;

      const body = {
        city: this.query.city,
        hotelCode: this.hotelCode,
        checkIn: this.query.checkIn,
        checkOut: this.query.checkOut,
        adult: Number(adult),
        child: Number(child),
        infant: Number(infant),
        lang: this.query.lang,
        includeFlight: this.query.includeFlight,
        returnLog: this.query.returnLog,
        manualDiscount: this.manualDiscount,
      };
      return body;
    },
    remarks() {
      // return this.currentHotel?.cancelRemarks?.replace(/\r\n/g, '<br />') ?? '';
      // return this.currentHotel?.cancelRemarks ?? '';
      let strRemarks = '';

      if (this.currentHotel?.remark !== '' && this.currentHotel?.remark !== null) {
        strRemarks += `Terms: \r\n ${this.currentHotel.remark.replace('<br>', '\r\n')} \r\n \r\n`;
      }

      if (this.currentHotel?.cancelRemarks !== '' && this.currentHotel?.cancelRemarks !== null) {
        strRemarks += `Cancellation policy: \r\n ${this.currentHotel.cancelRemarks}`;
      }
      return strRemarks;
    },
  },
  watch: {
    roomOccupation() {
      this.getSubmitHotelsFromServer();
    },
    roomKind() {
      this.basis = this.noHotels ? null : this.optionBasis[0].value;
      this.setCurrentHotel();
      this.oldKind = this.roomKind;
    },
    basis() {
      this.setCurrentHotel();
      this.oldBasis = this.basis;
    },
    hotel() {
      this.roomKind = this.hotel.roomCode;
      this.basis = this.hotel.basis;
    },
  },
  created() {
    this.currentHotel = this.hotel;
  },
  mounted() {
    setTimeout(() => {
      this.roomKind = this.hotel.roomCode;
      this.basis = this.hotel.basis;
      this.oldKind = this.roomKind;
      this.oldBasis = this.basis;
    }, 1000);
  },
  methods: {
    setCurrentHotel() {
      const { roomKind, basis, hotel, submitHotels } = this;
      const { restHotels } = hotel;
      const hotelList = submitHotels || restHotels;
      if (!hotelList || !hotelList.length) {
        this.currentHotel = hotel;
        return;
      }

      this.currentHotel = hotelList.find((one) => (one.roomCode === roomKind && one.basis === basis));
    },
    async getSubmitHotelsFromServer() {
      const { priceList } = this;
      const body = this.queryBody;
      body.subCalling = true;
      body.suppliers = [this.supplierCode];

      if (!body.city || !body.checkIn || !body.checkOut || !body.adult || !body.suppliers) {
        this.$bvModal.msgBoxOk(
          this.$t('search-result.dont-remove-query'),
          {
            title: this.$t('product-page.expire-title'),
            dialogClass: 'noSearchResult',
            okVariant: 'success',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
          },
        );
        return;
      }
      this.isLoading = true;
      const response = await this.$store.dispatch('FETCH_PACKAGE_BY_SEARCH', body);
      this.isLoading = false;
      this.submitHotels = response[0].data.data.filter((ht) => ht.priceList === priceList);
      this.noHotels = this.submitHotels.length === 0;

      if (this.noHotels) {
        this.roomKind = null;
        this.basis = null;
      } else {
        this.roomKind = this.oldKind && this.optionRoomClasses.findIndex((cls) => cls.value === this.oldKind) > -1 ? this.oldKind : this.optionRoomClasses[0].value;
        this.basis = this.oldBasis && this.optionBasis.findIndex((bss) => bss.value === this.oldBasis) ? this.oldBasis : this.optionBasis[0].value;
      }

      this.oldKind = this.roomKind;
      this.oldBasis = this.basis;

      this.setCurrentHotel();
    },
    orderNow() {
      const { marketerId, subMarketerId } = this;
      const { priceList } = this;
      let body = this.queryBody;
      if (marketerId && marketerId !== '') body = { ...body, marketerId };
      if (subMarketerId && subMarketerId !== '') body = { ...body, subMarketerId };
      body.roomCode = this.roomKind;
      body.basisCode = this.basis;
      body.priceList = priceList;
      body.suppliers = this.supplierCode;
      // if (window.location.host.includes('localhost')) {
      this.$router.push({ path: '/product-page', query: body });
      // } else {
      //   const routeData = this.$router.resolve({ path: '/product-page', query: body });
      //   window.open(routeData.href, '_blank');
      // }
    },
  },
};
</script>

<style>

.fa-question-circle{
  color:#0162ac;
}

h3 > s, h5 s {
  margin-right: 5px;
  font-size: 0.75rem;
}

.price-area h5 {
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
}

.tooltip-inner {
  white-space: pre-line;
  max-width: 350px;
}
</style>
